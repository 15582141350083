import set from "date-fns/set";
import format from "date-fns/format";
import { i18n } from "~/localization/i18n";

export const toPrettyLocaleDateString = (
    date,
    options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    }
) => {
    const dateObject = typeof date === "string" ? new Date(date) : date;

    if (!dateObject) {
        return "";
    }

    return dateObject.toLocaleString(i18n.languages[0], options);
};

export const dateFormatType = {
    DATE: 0,
    DATETIME: 1,
    TIME: 4,
};

const roundToNextMinute = (date) => {
    const ms = 1000 * 60;

    return new Date(Math.ceil(date.getTime() / ms) * ms);
};

export const formatTimeWindow = (timingWindowStart, timingWindowEnd) => {
    const [hours, minutes, seconds] = timingWindowEnd.split(":");
    const endDateTime = roundToNextMinute(
        set(new Date(), { hours, minutes, seconds })
    );

    return `${timingWindowStart.substring(0, 5)} - ${format(
        endDateTime,
        "HH:mm"
    )}`;
};
