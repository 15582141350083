import PropTypes from "prop-types";
import React from "react";
import format from "date-fns/format";
import { DateTimePicker } from "~/components/Base/DateTimePicker/DateTimePicker";

export function DateTimeItem({
  ariaDescribedBy,
  className,
  label,
  maxDate,
  oid,
  onChange,
  options,
  placeholder,
  readOnly,
  valid,
  value,
}) {
  const formatDate = (date) =>
    date instanceof Date ? format(date, "yyyy-MM-dd'T'HH:mm") : date;

  return (
    <DateTimePicker
      ariaDescribedBy={ariaDescribedBy}
      ariaLabel={label}
      className={className}
      disabled={readOnly}
      id={oid}
      onChange={(value) => onChange(formatDate(value))}
      options={{ ...options, ...(maxDate && { maxDate }) }}
      placeholder={placeholder}
      valid={valid}
      value={value ? new Date(value) : value}
    />
  );
}

DateTimeItem.propTypes = {
  ariaDescribedBy: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  oid: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.string,
};
