import "./FileDetails.css";
import PropTypes from "prop-types";
import classNames from "classnames";
import { formatSize } from "~/helpers/format-helpers";

export const DisplayThumbnail = {
  LEFT: "left",
  NONE: "none",
  BELOW: "below",
};

export function FileDetails({
  defaultThumbnail,
  displayThumbnail = DisplayThumbnail.LEFT,
  fileName,
  fileSize,
  isProcessing,
  onClick,
  thumbnail,
}) {
  return (
    <section
      className={classNames({
        "file-details": true,
        "file-details--link": !!onClick,
        "file-details--processing": isProcessing,
      })}
    >
      {displayThumbnail !== DisplayThumbnail.NONE &&
        (thumbnail ? (
          <button className="file-details__button file-details__thumbnail" type="button" onClick={onClick}>
            <img
              className="file-details__thumbnail-image"
              src={`data:image/png;base64,${thumbnail}`}
              alt={fileName}
            />
          </button>
        ) : (
          defaultThumbnail
        ))}
      <dl className="file-details__meta">
        <dd>{formatSize(fileSize)}</dd>
        <dt>
          <button className="file-details__button" onClick={onClick} type="button">
            {fileName}
            </button>
        </dt>
      </dl>
    </section>
  );
}

FileDetails.propTypes = {
  defaultThumbnail: PropTypes.object,
  displayThumbnail: PropTypes.oneOf(Object.values(DisplayThumbnail)),
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  isProcessing: PropTypes.bool,
  onClick: PropTypes.func,
  thumbnail: PropTypes.string,
};
