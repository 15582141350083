import "./Countdown.css";

import PropTypes from "prop-types";
import intervalToDuration from "date-fns/intervalToDuration";
import { useCallback, useState, useEffect, useRef } from "react";

export function Countdown({ end }) {
  const getRemainingTime = useCallback(() => {
    const prefixWithZero = (num) => (num < 10 ? `0${num}` : num);

    const duration = intervalToDuration({
      start: Date.now(),
      end: new Date(end),
    });

    const hours = prefixWithZero(duration.hours);
    const minutes = prefixWithZero(duration.minutes);
    const seconds = prefixWithZero(duration.seconds);

    return `${hours}:${minutes}:${seconds}`;
  }, [end]);

  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  const interval = useRef();

  useEffect(() => {
    interval.current = setInterval(
      () => setRemainingTime(getRemainingTime()),
      1000
    );

    return () => clearInterval(interval.current);
  }, [getRemainingTime]);

  return <div className="countdown">{remainingTime}</div>;
}

Countdown.propTypes = {
  end: PropTypes.string,
};
