import PropTypes from "prop-types";
import { i18n } from "~/localization/i18n";
import { formatTimeWindow } from "~/helpers/date-helpers";

export function DateRange({ endDate, label, startDate, timeEnd, timeStart }) {
  // Adding direction to the date container fixes issue with the order of label and day in date in arabic (49180)
  const direction = window.getComputedStyle(document.body).direction;

  const start = new Date(startDate).toLocaleDateString(i18n.languages[0]);
  const end = new Date(endDate).toLocaleDateString(i18n.languages[0]);

  const hasTimeRange = timeEnd && timeStart;

  return (
    <>
      {label && <span>{`${label} `}</span>}
      <span dir={direction}>
        {`${start} - ${end}`}
        {hasTimeRange && `, ${formatTimeWindow(timeStart, timeEnd)}`}
      </span>
    </>
  );
}

DateRange.propTypes = {
  endDate: PropTypes.string.isRequired,
  label: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  timeEnd: PropTypes.string,
  timeStart: PropTypes.string,
};
