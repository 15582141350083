import PropTypes from "prop-types";
import classNames from "classnames";
import { useContext, useEffect, useRef } from "react";
import { useFetchIsRoomActive } from "~/hooks/api/whereby/useFetchIsRoomActive";
import { BusyStateContext } from "~/context/BusyStateContext";

import "./VideoCall.css";

const VIDEO_CALL_ID = "whereby-video-call";
const IS_ACTIVE_POLLING = 60000; // Polling interval in milliseconds (1 min)

export function VideoCall({ allow = "camera; microphone; fullscreen; speaker; display-capture", hidden, room, title }) {
  const { addBusyItem, removeBusyItem } = useContext(BusyStateContext);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    // Resume inactivity timeout when call has ended
    return () => {
      removeBusyItem(VIDEO_CALL_ID);
    };
  }, [removeBusyItem]);

  useFetchIsRoomActive(room.meetingId, IS_ACTIVE_POLLING, (data) => {
    // Prevent keeping the user active when call has ended in case response comes back after user has closed the call
    if (!isMounted.current) {
      return;
    }

    // Prevent being logged out during an active call
    if (data) {
      addBusyItem(VIDEO_CALL_ID);
    } else {
      removeBusyItem(VIDEO_CALL_ID);
    }
  });

  return (
    <iframe
      className={classNames({
        "video-call--hidden": hidden,
      })}
      title={title}
      src={room.roomUrl}
      allow={allow}
    />
  );
}

VideoCall.propTypes = {
  allow: PropTypes.string,
  hidden: PropTypes.bool,
  room: PropTypes.shape({
    meetingId: PropTypes.string.isRequired,
    roomUrl: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
