import { isDecrement, isIncrement } from "~/helpers/keyboard-controls";

export const getNextIndex = (currentIndex, keyName, choices) => {
    const nextIndex = currentIndex;

    if (currentIndex === 0 && isDecrement(keyName)) {
        return choices.length - 1;
    }

    if (isDecrement(keyName)) {
        return currentIndex - 1;
    }

    if (currentIndex === choices.length - 1 && isIncrement(keyName)) {
        return 0;
    }

    if (isIncrement(keyName)) {
        return currentIndex + 1;
    }

    return nextIndex;
};
