import PropTypes from "prop-types";
import classNames from "classnames";
import "./VasScaleTicks.css";

export function VasScaleTicks({ vertical }) {
  return (
    <div
      className={classNames({
        "vas-scale-ticks": true,
        "vas-scale-ticks--vertical": vertical,
      })}
    >
      {Array.from(Array(9), (x, i) => {
        return 10 + i * 10;
      }).map((i) => {
        const style = vertical
          ? { top: `${i}%`, transform: `translateY(-${i}%)` }
          : {
              left: `${i}%`,
              transform: `translateX(-${i}%)`,
            };
        return (
          <div key={i} className="vas-scale-ticks__tick" style={style} />
        );
      })}
    </div>
  );
}

VasScaleTicks.propTypes = {
  vertical: PropTypes.bool.isRequired,
};
