import { Navigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { useFetchSubmittedForm } from "../../hooks/api/form/useFetchSubmittedForm";
import { Box } from "~/components/Base/Box/Box";
import { FormHeader } from "../Form/FormHeader/FormHeader";
import { FormContent } from "../Form/FormContent/FormContent";
import { i18n } from "~/localization/i18n";
import { convertFormValidation } from "../Form/shared/convertFormValidation";
import {
  updateVisibility,
  createVisibilityFunctions,
} from "../Form/shared/form-visibility.js";
import "./Pdf.css";
import { useAuth } from "react-oidc-context";
import { getCookie } from "~/helpers/cookies";

export function Pdf() {
  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth();
  const { control } = useForm({ delayError: 500, mode: "onChange" });
  const { formId } = useParams();
  const {
    submittedForm,
    isLoading: isLoadingSubmittedForm,
    error,
  } = useFetchSubmittedForm(formId, (data) => {
    const form = data.questModel;
    convertFormValidation(form);
    updateVisibility(form, createVisibilityFunctions(form));
  });
  const hasTokenInCookie = getCookie(import.meta.env.VITE_TOKEN_COOKIE_NAME) != null;

  // Wait for the authentication to be loaded before redirecting
  // Skip waiting for the authentication to be loaded if the token is in the cookie
  if (!hasTokenInCookie && isLoadingAuth) {
    return null;
  }

  if (!hasTokenInCookie && !isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (isLoadingSubmittedForm) {
    return (
      <div>
        <b>loading...</b>
      </div>
    );
  }

  if (error) {
    return (
      <div>
        {error.status} {error.statusText} - {error.url}
      </div>
    );
  }

  const questModel = submittedForm.questModel;

  if (
    questModel.identifierModel.Language !== null &&
    i18n.language !== questModel.identifierModel.Language
  ) {
    i18n.changeLanguage(questModel.identifierModel.Language);
  }
  const metadata = submittedForm.pdfMetadataViewModel;

  // The pdf-header and pdf-footer are picked automatically out of the HTML when generating the PDF
  const pdfHeader = [
    metadata.subjectKey,
    metadata.formDisplayLabel,
    metadata.activityName,
    metadata.studyEventLabel,
    metadata.siteName,
    metadata.studyName,
  ].join(" | ");

  const pdfFooter = [
    metadata.formAuthor,
    metadata.protocolVersion,
    metadata.version,
    metadata.dateTime,
    "{pageNumber} of {totalPages}",
  ].join(" | ");

  return (
    <div className="pdf">
      <div id="pdf-header" className="pdf__header">
        {pdfHeader}
      </div>
      <div id="pdf-footer" className="pdf__footer">
        {pdfFooter}
      </div>
      {questModel.itemGroups
        .filter((currentItemGroup) => currentItemGroup.isVisible)
        .map((currentItemGroup, index) => {
          return (
            <div className="pdf__page" key={currentItemGroup?.oid ?? index}>
              <Box>
                <FormHeader
                  currentPage={index + 1}
                  formName={questModel.formName}
                  itemGroupLabel={currentItemGroup?.label}
                  pageCount={questModel.itemGroups.length}
                  valid={true}
                  isThankYouPage={false}
                  isPending={false}
                  isReadyToSubmit={true}
                  sticky={false}
                />
                <FormContent
                  control={control}
                  errors={null}
                  formData={questModel.formData}
                  itemGroups={[currentItemGroup]}
                  readOnly={true}
                  onChange={() => {}}
                />
              </Box>
            </div>
          );
        })}
    </div>
  );
}
