import PropTypes from "prop-types";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/Base/Button/Button";
import CameraIcon from "~/content/icons/camera.svg?react";
import { VideoAction } from "./VideoAction";
import { VideoControls } from "./VideoControls";
import { VideoContext } from "~/context/VideoContext";
import "./VideoOverlayJoin.css";

export function VideoOverlayJoin({ activeRoom }) {
  const { t } = useTranslation();
  const { setRoom } = useContext(VideoContext);

  const joinCall = () => {
    setRoom(activeRoom);
  };

  return (
    <VideoControls minimized={true}>
      <VideoAction className="video-overlay-join__action" onClick={joinCall}>
        <Button
          color="green"
          size="large"
          icon={<CameraIcon />}
          label={t("home.video_call.join")}
        />
      </VideoAction>
    </VideoControls>
  );
}

VideoOverlayJoin.propTypes = {
  activeRoom: PropTypes.object,
};
