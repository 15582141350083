import classNames from "classnames";
import PropTypes from "prop-types";
import { useRef } from "react";

import { getNextIndex } from "./arrow-controls";
import { isArrowKey } from "~/helpers/keyboard-controls";

import { ToggleButton } from "../ToggleButton/ToggleButton";

import "./ToggleGroup.css";

export const Orientation = Object.freeze({
  Horizontal: "horizontal",
  Vertical: "vertical",
});

export function ToggleGroup({
  ariaDescribedBy,
  className,
  choices = [],
  disabled,
  id,
  multiple = false,
  onChange,
  orientation,
  valid = true,
  value: selected,
}) {
  const focused = useRef();

  const isSelected = (value) =>
    multiple ? selected.some((s) => s === value) : selected === value;

  const getTabIndex = (index, value) =>
    multiple || isSelected(value) || (!selected && index === 0) ? 0 : -1;

  const onKeyDown = ({ currentTarget, key }) => {
    if (isArrowKey(key)) {
      const currentIndex = choices.findIndex(
        (c) => c.value === focused.current
      );
      currentTarget.children[getNextIndex(currentIndex, key, choices)].focus();
    }
  };

  const onCheckboxGroupSelectionChange = (value) =>
    isSelected(value)
      ? selected.filter((v) => v !== value)
      : [value, ...selected];

  const onSelectedChange = (value) =>
    onChange(multiple ? onCheckboxGroupSelectionChange(value) : value);

  return (
    <div
      id={id}
      onKeyDown={onKeyDown}
      className={classNames({
        [className]: !!className,
        "toggle-group": true,
        "toggle-group--vertical": orientation === Orientation.Vertical,
        "toggle-group--invalid": !valid,
      })}
      role={multiple ? "group" : "radiogroup"}
    >
      {choices.length &&
        choices.map((c, i) => (
          <ToggleButton
            ariaDescribedBy={ariaDescribedBy}
            ariaInvalid={!valid}
            disabled={disabled}
            isSelected={isSelected(c.value)}
            key={c.value}
            label={c.label}
            onClick={() => !disabled && onSelectedChange(c.value)}
            onFocus={(value) => {
              focused.current = value;
            }}
            role={multiple ? "checkbox" : "radio"}
            tabIndex={getTabIndex(i, c.value)}
            value={c.value}
          />
        ))}
    </div>
  );
}

ToggleGroup.propTypes = {
  ariaDescribedBy: PropTypes.string,
  className: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.array,
      ]),
    })
  ),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf([Orientation.Vertical, Orientation.Horizontal])
    .isRequired,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};
