import React from "react";
import PropTypes from "prop-types";

/**
 * A component that renders text with line breaks.
 * Each "\<br \/>" in the input text is converted to an actual HTML line break element.
 */
export function TextWithLineBreaks({ text }) {
  if (!text) {
    return text;
  }

  return text.split("<br />").map((line, index, arr) => {
    const isLastLine = index === arr.length - 1;

    if (isLastLine) {
      return line;
    }

    return (
      // biome-ignore lint/suspicious/noArrayIndexKey: Items do not contain state and will not be re-ordered
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    );
  });
}

TextWithLineBreaks.propTypes = {
  text: PropTypes.string.isRequired,
};
