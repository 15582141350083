import {
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
  useMemo,
  useContext,
} from "react";

import PropTypes from "prop-types";

import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router";

import { useForm } from "react-hook-form";

import { formReducer, types } from "./shared/formReducer";
import { useFetchForm } from "~/hooks/api/form/useFetchForm";
import { LoadingContext, actionTypes } from "~/context/LoadingContext";

import { Box } from "~/components/Base/Box/Box";
import { FormContent } from "./FormContent/FormContent";
import { FormHeader } from "./FormHeader/FormHeader";
import { FormNavigation } from "./FormNavigation/FormNavigation";
import { FormSubmitPage } from "./FormSubmitPage/FormSubmitPage";
import { FormThankYouPage } from "./FormThankYouPage/FormThankYouPage";
import { getDeviceInformation, isReadOnly } from "./shared/form-helpers";

import "./Form.css";
import { useSendForm } from "~/hooks/api/form/useSendForm";

export const FORM_PAGE = Object.freeze({
  SUBMIT: "submit",
  THANK_YOU: "thank-you",
});

export function Form({ page }) {
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const { eventId } = useParams();

  const formHeader = useRef(null);
  const validateOnLoad = useRef(false);
  const isReadyToSubmit = useRef(false);

  const [{ form }, dispatch] = useReducer(formReducer, {
    form: { itemGroups: [] },
  });

  const [submitFailed, setSubmitFailed] = useState();
  const [submitErrorMessage, setSubmitErrorMessage] = useState();
  const { dispatch: dispatchLoading } = useContext(LoadingContext);
  const [showFormNavigation, setShowFormNavigation] = useState(false);
  const [previousPage, setPreviousPage] = useState();

  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
    handleSubmit,
    resetField,
  } = useForm({ delayError: 500, mode: "onChange" });

  const { sendForm, isSending } = useSendForm(eventId);

  const { error, isLoading } = useFetchForm(eventId, (data) =>
    dispatch({
      type: types.SET_FORM,
      payload: data,
    })
  );

  const readOnly = useMemo(() => isReadOnly(form?.status), [form]);
  const isThankYouPage = page === FORM_PAGE.THANK_YOU;
  const isSubmitPage = page === FORM_PAGE.SUBMIT;
  const visibleItemGroups = form.itemGroups.filter((ig) => ig.isVisible);
  const pageCount = visibleItemGroups.length;
  const currentPage = isSubmitPage || isThankYouPage ? pageCount + 1 : Number.parseInt(page);
  const displayPageCount = readOnly ? pageCount : pageCount + 1; // includes submit page when not readonly
  const isPageOutOfRange =  currentPage > displayPageCount || currentPage < 1;
  const currentItemGroup = visibleItemGroups[currentPage - 1];

  const goToPage = (toPage) => navigate(`/events/${eventId}/form/${toPage}`);

  const submitForm = async () => {
    const LOADING_KEY = "submit-form";
    dispatchLoading({
      type: actionTypes.ADD_LOADING_ITEM,
      key: LOADING_KEY,
    });

    try {
      const result = await sendForm({ formData: form.formData, ...getDeviceInformation() });
      if (result.success) {
        navigate(`/events/${eventId}/form/thank-you`);
      } else {
        setSubmitErrorMessage(result.message);
        setSubmitFailed(true);
      }
    } catch (e) {
      setSubmitFailed(true);
    } finally {
      dispatchLoading({
        type: actionTypes.REMOVE_LOADING_ITEM,
        key: LOADING_KEY,
      });
    }
  };

  useEffect(() => {
    const LOADING_KEY = "form";
    const type = isLoading
      ? actionTypes.ADD_LOADING_ITEM
      : actionTypes.REMOVE_LOADING_ITEM;

    dispatchLoading({
      type,
      key: LOADING_KEY,
    });
  }, [dispatchLoading, isLoading]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: This triggers a validation when item group changes. Could be good to look into if there is a better way of achieving this.
  useEffect(() => {
    validateOnLoad.current = searchParams.get("validate") === "true";

    if (validateOnLoad.current) {
      trigger();
    }
  }, [currentItemGroup]);

  useEffect(() => {
    if (!submitFailed) {
      setSubmitErrorMessage();
    }
  }, [submitFailed]);

  const setItemValue = useCallback(
    (id, value, format) => {
      dispatch({
        type: types.SET_ITEM_VALUE,
        payload: { id, value, format, resetField },
      });
    },
    [resetField]
  );

  // Reset the show form navigation when navigating to a new page
  // the form navigation will be hidden until the form content animation is done
  if (page !== previousPage) {
    setPreviousPage(page);
    setShowFormNavigation(false);
  }

  const getNavigation = () => {
    return (
      <FormNavigation
        currentPage={currentPage}
        readOnly={readOnly || isSending}
        pageCount={displayPageCount}
        isThankYouPage={isThankYouPage}
        isSubmitPage={isSubmitPage}
        onClickPrevious={(page) => {
          if (page < 1) {
            navigate("/events");
          } else {
            clearErrors();
            setSubmitFailed(false);
            goToPage(page);
          }
        }}
        onClickNext={(page) => {
          handleSubmit(() => {
            page > pageCount
              ? navigate(`/events/${eventId}/form/submit`)
              : goToPage(page);
          })();
        }}
        onSubmit={() => {
          if (isReadyToSubmit.current) {
            submitForm();
          }
        }}
      />
    );
  };

  const getFormContent = () => {
    if (isSubmitPage) {
      return (
        <FormSubmitPage
          submitFailed={submitFailed}
          submitErrorMessage={submitErrorMessage}
          itemGroups={visibleItemGroups}
          formData={form.formData}
          onAnimationEnd={() => {
            setShowFormNavigation(true);
          }}
          onSubmitValidation={(valid) => {
            isReadyToSubmit.current = valid;
          }}
        />
      );
    }

    if (isThankYouPage) {
      return (
        <FormThankYouPage
          onAnimationEnd={() => {
            setShowFormNavigation(true);
          }}
        />
      );
    }

    return (
      <FormContent
        control={control}
        errors={errors}
        formData={form.formData}
        itemGroups={[currentItemGroup]}
        readOnly={readOnly || isSending}
        onAnimationEnd={() => {
          setShowFormNavigation(true);
        }}
        onChange={setItemValue}
        onReset={(id) => resetField(id)}
        formHeaderRef={formHeader}
      />
    );
  };

  if (isLoading) {
    return null;
  }

  // Navigate to first page of form if page is out of range 
  // and form has more than one page (pageCount is truthy)
  if (pageCount && isPageOutOfRange) {
    return <Navigate to={`/events/${eventId}/form/1`} />;
  }

  return (
    <>
      <Box footer={getNavigation()} showFooter={showFormNavigation}>
        <FormHeader
          currentPage={currentPage ? currentPage : pageCount}
          formName={form.formName}
          isPending={isLoading}
          isReadyToSubmit={isReadyToSubmit.current}
          isThankYouPage={isThankYouPage}
          pageCount={displayPageCount}
          ref={formHeader}
          showFetchError={!!error}
          valid={Object.keys(errors).length === 0}
        />
        {form && getFormContent()}
      </Box>
    </>
  );
}

Form.propTypes = {
  page: PropTypes.string.isRequired,
};
