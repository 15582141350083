import useSWR from "swr";
import { http } from "~/services/http";

export const useFetchFooter = () => {
  const {
    data: footerDetails,
    isLoading,
    error,
  } = useSWR("/api/meta/footer", http.get, {
    revalidateOnFocus: false,
  });

  return { footerDetails, isLoading, error };
};
