import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DateTimeItem } from "../../shared/DateTimeItem";
import { dateFormatType } from "~/helpers/date-helpers";
import { ToggleGroup } from "~/components/Base/ToggleGroup/ToggleGroup";

import "./DateAndTime.css";

export function DateAndTime({
  ariaDescribedBy,
  label,
  format = dateFormatType.DATETIME,
  maxDate,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  const { t } = useTranslation();

  const TIME_UNKNOWN = "time_unknown";

  const handleUnknownTimeChange = (toggleValue) => {
    const newFormat =
      toggleValue[0] === TIME_UNKNOWN
        ? dateFormatType.DATE
        : dateFormatType.DATETIME;

    onChange(value, newFormat);
  };

  const handleDateTimeChange = (newValue) => onChange(newValue, format);

  return (
    <div className="date-and-time">
      <DateTimeItem
        ariaDescribedBy={ariaDescribedBy}
        format={format}
        label={label}
        oid={oid}
        maxDate={maxDate}
        onChange={handleDateTimeChange}
        options={{
          enableTime: format === dateFormatType.DATETIME,
        }}
        placeholder={t("form.date_input.select_date_time")}
        readOnly={readOnly}
        valid={valid}
        value={value}
      />
      <ToggleGroup
        className="date-and-time__unknown-button"
        choices={[
          { label: t("form.date_input.time_unknown"), value: TIME_UNKNOWN },
        ]}
        disabled={readOnly}
        onChange={handleUnknownTimeChange}
        orientation="horizontal"
        value={format === dateFormatType.DATE ? [TIME_UNKNOWN] : []}
        multiple
      />
    </div>
  );
}

DateAndTime.propTypes = {
  ariaDescribedBy: PropTypes.string,
  format: PropTypes.number,
  label: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  oid: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.string,
};
