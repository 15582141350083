import { useCallback } from "react";
import { useAuth } from "react-oidc-context";
import { eraseCookie } from "~/helpers/cookies";
import { appInsightsTelemetry } from "~/logging/applicationInsightsService";

let isSigningOut = false; // flag to prevent multiple signout calls

export function useSignOut() {
  const { signoutRedirect, removeUser, user } =
    useAuth();

  const signout = useCallback(async () => {
    if (isSigningOut) {
      return;
    }

    isSigningOut = true;

    try {
      eraseCookie(import.meta.env.VITE_TOKEN_COOKIE_NAME);

      if (!user) {
        return signoutRedirect();
      }

      const state = {
        ...(user.profile?.idp && { idp: user.profile.idp }),
        ...(user.profile?.locale && { locale: user.profile.locale }),
      };

      await signoutRedirect({ id_token_hint: user.id_token, state });
    } catch (error) {
      appInsightsTelemetry.trackException(error);
      await removeUser();
    } finally {
      isSigningOut = false;
    }
  }, [removeUser, signoutRedirect, user]);

  return signout;
}
