import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Button } from "~/components/Base/Button/Button";
import ArrowIcon from "~/content/icons/arrow.svg?react";

import "./FormNavigation.css";

export function FormNavigation({
  currentPage,
  isSubmitPage,
  isThankYouPage,
  onClickNext,
  onClickPrevious,
  onSubmit,
  pageCount,
  readOnly,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getButtons = () => {
    if (isThankYouPage) {
      return (
        <Button
          className="form-navigation__button form-navigation__button--center"
          color="black"
          label={t("form.thank_you.to_start_page")}
          onClick={() => navigate("/")}
          size="large"
        />
      );
    }

    if (isSubmitPage) {
      return (
        <>
          <Button
            className="form-navigation__button form-navigation__button--previous"
            size="large"
            color="gray"
            type="circular"
            icon={<ArrowIcon />}
            label={t("form.previous")}
            disabled={readOnly}
            onClick={() => onClickPrevious(currentPage - 1)}
          />
          <Button
            className="form-navigation__button form-navigation__button--submit"
            label={t("form.submit.submit")}
            size="large"
            color="green"
            disabled={readOnly}
            onClick={onSubmit}
          />
        </>
      );
    }

    return (
      <>
        {(readOnly || currentPage > 1) && (
          <Button
            className="form-navigation__button form-navigation__button--previous"
            size="large"
            color="gray"
            type="circular"
            icon={<ArrowIcon />}
            label={t("form.previous")}
            onClick={() => onClickPrevious(currentPage - 1)}
          />
        )}
        {currentPage < pageCount && (
          <Button
            className="form-navigation__button form-navigation__button--next"
            size="large"
            color="green"
            type="circular"
            label={t("form.next")}
            icon={<ArrowIcon />}
            onClick={() => onClickNext(currentPage + 1)}
          />
        )}
      </>
    );
  };

  return <div className="form-navigation">{getButtons()}</div>;
}

FormNavigation.propTypes = {
  currentPage: PropTypes.number,
  isSubmitPage: PropTypes.bool,
  isThankYouPage: PropTypes.bool,
  onClickNext: PropTypes.func,
  onClickPrevious: PropTypes.func,
  onSubmit: PropTypes.func,
  pageCount: PropTypes.number,
  readOnly: PropTypes.bool,
};
