import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "./AnimatedCheck.css";

export function AnimatedCheck({ className, onAnimationEnd }) {
  const { t } = useTranslation();

  return (
    <svg
      className={classNames({
        "animated-check": true,
        [className]: !!className,
      })}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57 44"
      role="graphics-symbol"
      aria-label={t("general.check_mark")}
    >
      <path
        fill="none"
        stroke="#7b4dff"
        strokeWidth="6"
        strokeMiterlimit="10"
        d="m4 23 15 15L53 4"
        onAnimationEnd={(evt) => {
          if (evt.animationName === "fadeOut") {
            onAnimationEnd();
          }
        }}
      />
    </svg>
  );
}

AnimatedCheck.propTypes = {
  className: PropTypes.string,
  onAnimationEnd: PropTypes.func,
};
