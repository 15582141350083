import PropTypes from "prop-types";

import { useCallback, useEffect, useRef, useContext } from "react";

import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { errorDescribedBy } from "~/components/Base/ValidationError/errorDescribedBy";
import { isStaticItemType, isTextItem } from "../shared/form-helpers";
import { useHashScroll } from "~/hooks/useHashScroll";
import { BusyStateContext } from "~/context/BusyStateContext";

import {
  BoxSection,
  BoxSectionBackground,
} from "~/components/Base/Box/BoxSection/BoxSection";
import { FormItemLabel } from "./FormItemLabel/FormItemLabel";
import { ValidationError } from "~/components/Base/ValidationError/ValidationError";

import "./FormItem.css";
import classNames from "classnames";

export function FormItem({
  control,
  error,
  itemType,
  label,
  oid,
  onChange,
  onReset,
  readOnly,
  render,
  validation,
  value,
  ...props
}) {
  const { t } = useTranslation();

  // Context to handle busy form items and idle timer
  const { addBusyItem, removeBusyItem } = useContext(BusyStateContext);

  const itemRef = useRef();
  const debounceTimer = useRef();
  const internalValue = useRef(value);
  const isBusy = useRef(false);

  useHashScroll(itemRef, oid);

  const shouldDebounce = (itemType) =>
    isTextItem(itemType) || itemType === "VasScale";

  const onItemValueChange = (value, format, onValidatedChange, isReset) => {
    internalValue.current = value;
    if (isReset) {
      onReset(oid);
    } else {
      onValidatedChange(value);
    }

    if (shouldDebounce(itemType)) {
      onDebouncedChange(oid, value, format);
    } else {
      onChange(oid, value, format);
    }
  };

  const onDebouncedChange = (oid, value, format) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      onChange(oid, value, format);
    }, 500);
  };

  // Keep track of busy items to pause/resume idle timer
  const setBusy = useCallback(
    (itemBusy) => {
      // Early return if value did not change
      if (isBusy.current === itemBusy) {
        return;
      }

      isBusy.current = itemBusy;

      // Include missing busy item
      if (itemBusy) {
        addBusyItem(oid);
        return;
      }

      // Remove existing non-busy item
      if (!itemBusy) {
        removeBusyItem(oid);
      }
    },
    [oid, addBusyItem, removeBusyItem]
  );

  useEffect(() => {
    // Ensure to resume idle timer if navigated away during upload
    return function cleanup() {
      removeBusyItem(oid);
    };
  }, [oid, removeBusyItem]);

  const getIsReady = () => {
    if (!isBusy.current) {
      return true;
    }

    return itemType === "FileUpload"
      ? t("form.file_upload.busy")
      : t("form.busy");
  };

  // Append ready validation rule
  // This is done here and not in convertFormValidation since it depends on item state and not item value
  const rules =
    validation == null
      ? null
      : {
          ...validation,
          validate: {
            ...validation.validate,
            ready: getIsReady,
          },
        };

  const backgroundColor = isStaticItemType(itemType)
    ? BoxSectionBackground.GREY
    : BoxSectionBackground.WHITE;

  const RenderComponent = ({
    field: { ref, onChange: onValidatedChange, value, ...rest },
  }) => {
    const handleChange = useCallback(
      (value, format, isReset) =>
        onItemValueChange(value, format, onValidatedChange, isReset),
      [onValidatedChange]
    );

    return render({
      ariaDescribedby: errorDescribedBy(oid),
      onChange: handleChange,
      onBusy: setBusy,
      readOnly,
      valid: !error,
      value: internalValue.current,
    });
  };

  return (
    <BoxSection backgroundColor={backgroundColor} {...props}>
      <div
        className={classNames({
          "form-item": true,
          "form-item--static": isStaticItemType(itemType),
        })}
        ref={itemRef}
      >
        {!isStaticItemType(itemType) && (
          <FormItemLabel oid={oid} label={label} />
        )}
        {isStaticItemType(itemType) ? (
          render({})
        ) : (
          <>
            <Controller
              control={control}
              defaultValue={internalValue.current}
              key={oid}
              name={oid}
              rules={rules}
              render={RenderComponent}
            />
            <ValidationError
              id={errorDescribedBy(oid)}
              message={error?.message}
            />
          </>
        )}
      </div>
    </BoxSection>
  );
}

FormItem.propTypes = {
  control: PropTypes.object,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  itemType: PropTypes.string,
  label: PropTypes.string,
  oid: PropTypes.string,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  readOnly: PropTypes.bool,
  render: PropTypes.func,
  validation: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
};
