import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DashboardTile } from "~/components/Home/Dashboard/shared/DashboardTile/DashboardTile";

import "./NoEvent.css";

export function NoEvent({ testId, studyLocked }) {
  const { t } = useTranslation();

  return (
    <DashboardTile disabled testId={testId}>
      <div className="no-event">
        <h2>
          {t("home.next_event.no_scheduled")}
          <br />
          {studyLocked ? t("home.next_event.questionnaires_now_when_locked") : t("home.next_event.questionnaires_now")}
        </h2>
      </div>
    </DashboardTile>
  );
}

NoEvent.propTypes = {
  testId: PropTypes.string,
  studyLocked: PropTypes.bool.isRequired
};
