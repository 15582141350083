import PropTypes from "prop-types";
import { createRef, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/Base/Button/Button";
import { VideoCall } from "~/components/Base/VideoCall/VideoCall";
import { VideoAction, VideoActionSize } from "./VideoAction";
import { VideoControls } from "./VideoControls";
import LeaveIcon from "~/content/icons/close.svg?react";
import MinimizeIcon from "~/content/icons/minimize.svg?react";
import MaximizeIcon from "~/content/icons/fullscreen.svg?react";
import VideoCallIcon from "~/content/icons/call-ongoing.svg?react";
import { VideoContext } from "~/context/VideoContext";

import "./VideoOverlayVideoCall.css";

export function VideoOverlayVideoCall({ minimized, onMinimize, room }) {
  const { t } = useTranslation();
  const { setRoom } = useContext(VideoContext);

  const leaveCallButton = createRef();

  useEffect(() => {
    leaveCallButton.current?.focus();
  }, [leaveCallButton]);

  const leaveCall = () => {
    setRoom(null);
  };

  const getVideoControls = () => {
    if (minimized) {
      return (
        <>
          <VideoAction size={VideoActionSize.LARGE}>
            <Button
              color="gray"
              type="rectangle"
              icon={<VideoCallIcon />}
              label={t("home.video_call.maximize")}
            />
          </VideoAction>
          <VideoAction size={VideoActionSize.LARGE}>
            <Button
              id="video-call__maximize-button"
              color="gray"
              type="circular"
              icon={<MaximizeIcon />}
              label={t("home.video_call.maximize")}
            />
            <label htmlFor="video-call__maximize-button">
              {t("home.video_call.maximize")}
            </label>
          </VideoAction>
        </>
      );
    }

    return (
      <>
        <VideoAction onClick={leaveCall}>
          <Button
            buttonRef={leaveCallButton}
            id="video-call__leave-button"
            color="orange"
            type="circular"
            icon={<LeaveIcon />}
            label={t("home.video_call.leave")}
          />
          <label htmlFor="video-call__leave-button">
            {t("home.video_call.leave")}
          </label>
        </VideoAction>
        <VideoAction onClick={onMinimize}>
          <Button
            id="video-call__minimize-button"
            color="gray"
            type="circular"
            icon={<MinimizeIcon />}
            label={t("home.video_call.minimize")}
          />
          <label htmlFor="video-call__minimize-button">
            {t("home.video_call.minimize")}
          </label>
        </VideoAction>
      </>
    );
  };

  return (
    <>
      <VideoControls minimized={minimized}>{getVideoControls()}</VideoControls>
      <VideoCall
        hidden={minimized}
        room={room}
        title={t("home.video_call.video_call")}
      />
    </>
  );
}

VideoOverlayVideoCall.propTypes = {
  minimized: PropTypes.bool,
  onMinimize: PropTypes.func.isRequired,
  room: PropTypes.shape({
    meetingId: PropTypes.string.isRequired,
    roomUrl: PropTypes.string.isRequired,
  }).isRequired,
};
