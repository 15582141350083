import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAuth } from "react-oidc-context";
import { useFetchStudyInfo } from "~/hooks/api/study/useFetchStudyInfo";
import { i18n } from "~/localization/i18n";
import { Button } from "~/components/Base/Button/Button";
import "./Welcome.css";

export function Welcome() {
  const navigate = useNavigate();
  const { studyInfo } = useFetchStudyInfo();
  const { user } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.profile.lang);
    }
  }, [user]);

  const username = user.profile.name;
  const navigateLink = `${window.location.origin}?login_hint=${user.profile.name}`;
  const navigateToHomePage = () => navigate("/");

  return (
    <section className="welcome-page">
      <div>
        <h1 className="login__animated-title">
          <Trans i18nKey="welcome_page.welcome_user" value={{ username }}>
            Welcome, user <span className="login__user-name">{{ username }}</span>
          </Trans>
        </h1>
        <p>
          {t("welcome_page.username_is_unique_identity_for_the_study", {
            studyName: studyInfo?.studyName,
          })}
          <br />
          {t("welcome_page.instruction_following_url")}
          <br />
          <a href={navigateLink}>{navigateLink}</a>
        </p>
        <div className="pt-20">
          <Button
            size="large"
            color="purple-dark"
            className="button-continue"
            label={t("welcome_page.continue_button")}
            onClick={navigateToHomePage}
          />
        </div>
      </div>
    </section>
  );
}
