import PropTypes from "prop-types";
import classNames from "classnames";
import Thumb from "~/content/images/vas_thumb.svg?react";
import "./VasScaleThumb.css";

const tickWidth = 2;

export function VasScaleThumb({ color, position, vertical }) {
  const style = vertical
    ? { top: `calc(${100 - position}% - ${1 - tickWidth * (position / 100)}px` }
    : { left: `calc(${position}% + ${1 - tickWidth * (position / 100)}px` };
  return (
    <div
      className={classNames({
        "vas-scale-thumb": true,
        "vas-scale-thumb--vertical": vertical,
      })}
      style={style}
    >
      <Thumb className="vas-scale-thumb__icon" style={{ fill: color }} />
    </div>
  );
}

VasScaleThumb.propTypes = {
  color: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  vertical: PropTypes.bool.isRequired,
};
