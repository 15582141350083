import { useTranslation } from "react-i18next";
import InfoIcon from "~/content/icons/info.svg?react";

import "./FetchError.css";

export function FetchError() {
  const { t } = useTranslation();

  return (
    <div className="fetch-error">
      <InfoIcon className="fetch-error__icon" />
      {t("general.errors.notification")} {t("general.errors.try_again")}
    </div>
  );
}
