import { UserManager, WebStorageStateStore } from "oidc-client-ts";

const userManagerConfig = Object.freeze({
  authority: import.meta.env.VITE_ME_IDP_URL,
  client_id: "new-viedocme-oidc",
  redirect_uri: `${window.location.origin}/signin-oidc`,
  silent_redirect_uri: `${window.location.origin}/signin-oidc`,
  post_logout_redirect_uri: `${window.location.origin}/signout-oidc`,
  revokeTokensOnSignout: true,
  accessTokenExpiringNotificationTimeInSeconds: 300, //5 mins
  loadUserInfo: true,
  scope:
    "openid profile offline_access viedoc.me.api viedoc.me.pdf settings monitor IdentityServerApi",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

const userManager = new UserManager(userManagerConfig);

export { userManager, userManagerConfig };
