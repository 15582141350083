import PropTypes from "prop-types";
import { List } from "~/components/Base/List/List";
import { UnansweredQuestionsListItem } from "../UnansweredQuestionsListItem/UnansweredQuestionsListItem";

export function UnansweredQuestionsList({ items, eventId }) {
  const itemsWithKey = items.map(({ page, item }, i) => ({
    page,
    item,
    key: item?.oid ?? i,
  }));

  return (
    <>
      {itemsWithKey && (
        <List
          items={itemsWithKey}
          render={({ page, item }) => (
            <UnansweredQuestionsListItem
              eventId={eventId}
              page={page}
              oid={item.oid}
              label={item.exportLabel}
            />
          )}
        />
      )}
    </>
  );
}

UnansweredQuestionsList.propTypes = {
  items: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
};
