import { useNavigate } from "react-router";
import { Button } from "~/components/Base/Button/Button";
import { useTranslation } from "react-i18next";

import "./NotFound.css";

export function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="not-found__content">
        <div className="not-found__title">{t("not_found.title")}</div>
        <div className="not-found__info">{t("not_found.details")}</div>
        <Button
          label={t("not_found.to_dashboard")}
          onClick={() => navigate("/")}
          color="green"
          size="large"
        />
      </div>
    </>
  );
}
