import useSWR from "swr";
import { http } from "~/services/http";

export const useFetchForm = (eventId, onSuccess) => {
  const {
    data: form,
    error,
    isLoading,
  } = useSWR(`/api/quest/${eventId}`, http.get, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false, // false to prevent current values from being reset on reconnect
    onSuccess,
  });

  return { form, isLoading, error };
};
