import { i18n } from "~/localization/i18n";

/* 
   The system translations are being used for all the text strings in the date picker.
   The flatpickr locale file is also being imported and used for configuration, for example for which day is the first of the week
   If flatpickr doesn't support the language we also have our own custom locale files for the missing languages.
   If no locale file is found we fallback to English locale config
*/

// Map Viedoc language codes to Flatpickr codes used for when they don't match
const localeMap = Object.freeze({
    el: "gr",
    kk: "kz",
    nb: "no",
    "sr-Cyrl": "sr-cyr",
    "sr-Latn": "sr",
    vi: "vn",
    "zh-Hans": "zh",
    "zh-TW": "zh-tw",
});

function getSystemTranslations() {
    return {
        weekdays: {
            shorthand: [
                i18n.t("form.date_input.weekdays.shorthand.sun"),
                i18n.t("form.date_input.weekdays.shorthand.mon"),
                i18n.t("form.date_input.weekdays.shorthand.tue"),
                i18n.t("form.date_input.weekdays.shorthand.wed"),
                i18n.t("form.date_input.weekdays.shorthand.thu"),
                i18n.t("form.date_input.weekdays.shorthand.fri"),
                i18n.t("form.date_input.weekdays.shorthand.sat"),
            ],
            longhand: [
                i18n.t("form.date_input.weekdays.longhand.sun"),
                i18n.t("form.date_input.weekdays.longhand.mon"),
                i18n.t("form.date_input.weekdays.longhand.tue"),
                i18n.t("form.date_input.weekdays.longhand.wed"),
                i18n.t("form.date_input.weekdays.longhand.thu"),
                i18n.t("form.date_input.weekdays.longhand.fri"),
                i18n.t("form.date_input.weekdays.longhand.sat"),
            ],
        },
        months: {
            shorthand: [
                i18n.t("form.date_input.months.shorthand.jan"),
                i18n.t("form.date_input.months.shorthand.feb"),
                i18n.t("form.date_input.months.shorthand.mar"),
                i18n.t("form.date_input.months.shorthand.apr"),
                i18n.t("form.date_input.months.shorthand.may"),
                i18n.t("form.date_input.months.shorthand.jun"),
                i18n.t("form.date_input.months.shorthand.jul"),
                i18n.t("form.date_input.months.shorthand.aug"),
                i18n.t("form.date_input.months.shorthand.sep"),
                i18n.t("form.date_input.months.shorthand.oct"),
                i18n.t("form.date_input.months.shorthand.nov"),
                i18n.t("form.date_input.months.shorthand.dec"),
            ],
            longhand: [
                i18n.t("form.date_input.months.longhand.jan"),
                i18n.t("form.date_input.months.longhand.feb"),
                i18n.t("form.date_input.months.longhand.mar"),
                i18n.t("form.date_input.months.longhand.apr"),
                i18n.t("form.date_input.months.longhand.may"),
                i18n.t("form.date_input.months.longhand.jun"),
                i18n.t("form.date_input.months.longhand.jul"),
                i18n.t("form.date_input.months.longhand.aug"),
                i18n.t("form.date_input.months.longhand.sep"),
                i18n.t("form.date_input.months.longhand.oct"),
                i18n.t("form.date_input.months.longhand.nov"),
                i18n.t("form.date_input.months.longhand.dec"),
            ],
        },
        rangeSeparator: i18n.t("form.date_input.range_seperator"),
        weekAbbreviation: i18n.t("form.date_input.week_abbreviation"),
        scrollTitle: i18n.t("form.date_input.scroll_title"),
        toggleTitle: i18n.t("form.date_input.toggle_title"),
        yearAriaLabel: i18n.t("form.date_input.year_aria_label"),
        monthAriaLabel: i18n.t("form.date_input.month_aria_label"),
        hourAriaLabel: i18n.t("form.date_input.hour_aria_label"),
        minuteAriaLabel: i18n.t("form.date_input.minute_aria_label"),
    };
}

// Returns the language code in the format the locale is being exported in the locale file
function getLocaleExportName(locale) {
    return locale === "sr-cyr" ? "sr" : locale.replace("-", "_"); // SerbianCyrillic is exported as sr instead of sr_cyr in flatpickr
}

async function importFlatpickrLocale(locale) {
    try {
        const flatpickrLocale = await import(
            `~/../node_modules/flatpickr/dist/esm/l10n/${locale}.js`
        );

        return flatpickrLocale;
    } catch {
        try {
            // Import custom locale if language doesn't exist in flatpickr
            const customLocal = await import(
                `~/localization/flatpickr/${locale}.js`
            );

            return customLocal;
        } catch {
            console.warn(`Locale file not found for locale: ${locale}`);
        }
    }
}

export async function getDateTimePickerLocale(language) {
    const systemTranslations = getSystemTranslations();
    const datePickerLocale = Object.hasOwn(localeMap, language)
        ? localeMap[language]
        : language;

    // English is the default language
    if (datePickerLocale === undefined || datePickerLocale === "en") {
        return {
            ...systemTranslations,
        };
    }

    const localeFile = await importFlatpickrLocale(datePickerLocale);
    const exportName = getLocaleExportName(datePickerLocale);
    const locale = localeFile ? localeFile.default[exportName] : {};

    return {
        ...locale,
        ...systemTranslations,
    };
}
