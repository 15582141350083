import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "@viedoc/viedoc-ui/viedoc-radio";

import "./NumericRatingScaleRadioButton.css";

export function NumericRatingScaleRadioButton({
  ariaDescribedBy,
  disabled = false,
  checked = false,
  label,
  name,
  onChange = () => {},
  showIndicatorArrow = false,
  valid = true,
  value,
}) {
  const radioButton = useRef();

  // Setting listener using addEventListener instead of using the onChange prop
  // since the web component does not support the onchange prop
  useEffect(() => {
    function handleOnChange(event) {
      if (onChange && typeof onChange === "function") {
        onChange(Number.parseInt(event.target.value));
      }
    }

    const currentRadioButton = radioButton.current;

    if (currentRadioButton) {
      currentRadioButton.addEventListener("change", handleOnChange);
    }

    return () => {
      if (currentRadioButton) {
        currentRadioButton.removeEventListener("change", handleOnChange);
      }
    };
  }, [onChange]);

  return (
    <viedoc-radio
      aria-describedby={ariaDescribedBy}
      checked={checked ? "true" : null}
      class={classNames({
        "numeric-rating-scale-radio-button": true,
        "numeric-rating-scale-radio-button--error": !valid,
        "numeric-rating-scale-radio-button--show-indicator-arrow":
          showIndicatorArrow,
      })}
      disabled={disabled ? "true" : null}
      id={label}
      label-position="bottom"
      name={name}
      ref={radioButton}
      type="radio"
      value={value}
    >
      {label}
    </viedoc-radio>
  );
}

NumericRatingScaleRadioButton.propTypes = {
  ariaDescribedBy: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showIndicatorArrow: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.number.isRequired,
};
