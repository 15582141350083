import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { VideoOverlayVideoCall } from "./VideoOverlayVideoCall";
import { VideoOverlayJoin } from "./VideoOverlayJoin";
import { Overlay } from "~/components/Base/Overlay/Overlay";
import logo from "~/content/images/logo-white.svg";

import "./VideoOverlay.css";

export function VideoOverlay({ room, activeRoom }) {
  const [minimized, setMinimized] = useState(false);
  const hasActiveCall = !!room;
  const hasPendingCall = !!activeRoom;

  const maximize = () => {
    if (minimized) {
      setMinimized(false);
    }
  };

  useEffect(() => {
    setMinimized(!room && !!activeRoom);
  }, [room, activeRoom]);

  return (
    <Overlay visible={hasActiveCall || hasPendingCall} minimized={minimized}>
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: TODO - update to make it accessible for keyboard users */}
      <div className="video-overlay" onClick={maximize}>
        <div className="video-overlay__content">
          {!minimized && (
            <header className="video-overlay__header">
              <div>
                <img
                  src={logo}
                  alt="Viedoc Me Logo"
                  className="video-overlay__header-logo"
                  height="24px"
                  width="150px"
                />
              </div>
            </header>
          )}
          <main>
            {hasActiveCall && (
              <VideoOverlayVideoCall
                minimized={minimized}
                onMinimize={() => setMinimized(true)}
                room={room}
              />
            )}
            {!hasActiveCall && hasPendingCall && (
              <VideoOverlayJoin activeRoom={activeRoom} />
            )}
          </main>
        </div>
      </div>
    </Overlay>
  );
}

VideoOverlay.propTypes = {
  room: PropTypes.object,
  activeRoom: PropTypes.object,
};
