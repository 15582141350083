import { http } from "~/services/http";
import useSWRMutation from "swr/mutation";

const fetcher = (url, { arg: uuid }) => {
  return http.get(`/api/studyfile/${uuid}`);
};

export function useFetchFileInfo() {
  const { trigger: fetchFileInfo } = useSWRMutation("/api/studyfile", fetcher);

  return { fetchFileInfo };
}
