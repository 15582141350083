import { http } from "~/services/http";
import useSWR from "swr";

export const useFetchDocuments = (onSuccess, onError) => {
  const {
    data: binderSummary,
    error,
    isLoading,
  } = useSWR("/api/document/bindersummary", http.get, {
    ...(!!onSuccess && { onSuccess: onSuccess }),
    ...(!!onError && { onError: onError }),
  });

  return { binderSummary, isLoading, error };
};
