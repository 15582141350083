import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const appInsightsTelemetry = new ReactPlugin();
const connectionString =
  import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTION_STRING;

let appInsights = null;

if (connectionString) {
  try {
    appInsights = new ApplicationInsights({
      config: {
        connectionString: connectionString,
        extensions: [appInsightsTelemetry],
        enableUnhandledPromiseRejectionTracking: true,
        enableAutoRouteTracking: true,
      },
    });

    appInsights.loadAppInsights();
  } catch (error) {
    console.error("Failed to initialize Application Insights", error);
  }
} else {
  console.warn(
    "Application Insights connection string not found. Skipping initialization."
  );
}

export { appInsightsTelemetry };
