import useSWR from "swr";
import { http } from "~/services/http";

export const useFetchNextEvent = (onSuccess, onError) => {
  const { data, error, isLoading } = useSWR("/api/user/events/next", http.get, {
    ...(!!onSuccess && { onSuccess: onSuccess }),
    ...(!!onError && { onError: onError }),
  });

  // If data status is 204 (http "No Content"), there is no next event
  const event = data?.status && data.status === 204 ? null : data;

  return { event, error, isLoading };
};
