import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useFetchFooter } from "~/hooks/api/meta/useFetchFooter";
import logo from "~/content/images/viedocTM.png";
import logoWhite from "~/content/images/viedocTM-white.png";

import "./Footer.css";

export const FooterTheme = {
  DARK: "dark",
  LIGHT: "light",
};

export function Footer({ alignText = "center", className, theme = "light" }) {
  const { t } = useTranslation();
  const { footerDetails, error } = useFetchFooter();

  const imageSrc = theme === FooterTheme.DARK ? logoWhite : logo;

  return (
    <footer
      className={classNames({
        footer: true,
        "footer--left-align": alignText === "left",
        "footer--dark": theme === FooterTheme.DARK,
        [className]: !!className,
      })}
    >
      <img
        src={imageSrc}
        alt="Viedoc"
        className="footer__logo"
        width="90px"
        height="40px"
      />
      {footerDetails && (
        <>
          <div>{footerDetails.copyright}</div>
          <div>{footerDetails.version}</div>
        </>
      )}
      {error && (
        <small className="footer__error">
          {t("general.errors.fetch", { target: "footer details" })}
        </small>
      )}
    </footer>
  );
}

Footer.propTypes = {
  alignText: PropTypes.oneOf(["center", "left"]),
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(FooterTheme)),
};
