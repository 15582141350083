import { http } from "~/services/http";
import useSWR from "swr";

export const useFetchEvents = (onSuccess, onError) => {
  const {
    data: events,
    error,
    isLoading,
  } = useSWR("/api/user/events", http.get, {
    ...(!!onSuccess && { onSuccess: onSuccess }),
    ...(!!onError && { onError: onError }),
  });

  return { events, isLoading, error };
};
