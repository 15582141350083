import PropTypes from "prop-types";

import { ListItem } from "../ListItem/ListItem";

import "./List.css";

export function List({ isDisabled, items = [], render }) {
  return (
    <ul className="list">
      {items.map((item) => {
        const disabled = isDisabled ? isDisabled(item) : false;

        return (
          <ListItem disabled={disabled} key={item.key}>
            {render(item)}
          </ListItem>
        );
      })}
    </ul>
  );
}

List.propTypes = {
  isDisabled: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  render: PropTypes.func,
};
