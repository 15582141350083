import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DashboardTile } from "../../shared/DashboardTile/DashboardTile";
import { useFetchRoom } from "~/hooks/api/whereby/useFetchRoom";
import { VideoContext } from "~/context/VideoContext";
import CameraIcon from "~/content/icons/camera.svg?react";
import NoCameraIcon from "~/content/icons/no-camera.svg?react";

import "./VideoCallTile.css";

export function VideoCallTile({ setFetchError }) {
  const { t } = useTranslation();
  const { room: activeRoom } = useFetchRoom({
    onSuccess: () => {
      setFetchError("video.active_room", false);
    },
    onError: () => {
      setFetchError("video.active_room", true);
    },
  });
  const { room: joinedRoom, setRoom } = useContext(VideoContext);

  const pendingCall = !!activeRoom;
  const hasActiveCall = !!joinedRoom;

  const joinCall = () => {
    if (pendingCall && !hasActiveCall) {
      setRoom(activeRoom);
    }
  };

  const getIcon = () =>
    pendingCall ? (
      <div
        className={classNames({
          "video-call-tile__badge": true,
          "video-call-tile__badge--active": !hasActiveCall,
          "video-call-tile__badge--disabled": hasActiveCall,
        })}
      >
        <CameraIcon className="video-call-tile__camera-icon" />
        Join
      </div>
    ) : (
      <div className="video-call-tile__badge">
        <NoCameraIcon className="video-call-tile__camera-icon" />
      </div>
    );

  return (
    <DashboardTile
      className={classNames({
        "video-call-tile": true,
        "video-call-tile--pending-call": pendingCall,
      })}
      controls={getIcon}
      disabled={!pendingCall || hasActiveCall}
      testId="video-call-tile"
      wrapperComponent={({ children, ...props }) => (
        <button
          disabled={!pendingCall || hasActiveCall}
          label={t("home.video_call.join")}
          onClick={joinCall}
          {...props}
        >
          {children}
        </button>
      )}
    >
      {pendingCall ? (
        <small>{t("home.video_call.attendee_waiting")}</small>
      ) : (
        <small>{t("home.video_call.notify")}</small>
      )}
      <h2>{t("home.video_call.video_call")}</h2>
    </DashboardTile>
  );
}

VideoCallTile.propTypes = {
  setFetchError: PropTypes.func.isRequired,
};
