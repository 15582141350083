import PropTypes from "prop-types";
import classNames from "classnames";
import WarningIcon from "~/content/icons/warning.svg?react";
import SuccessIcon from "~/content/icons/check.svg?react";

import "./StatusMessage.css";

export function StatusMessage({
  id,
  message,
  className,
  isError = false,
  isSuccess = false,
}) {
  return (
    <div
      id={id}
      className={classNames({
        "status-message": true,
        [className]: !!className,
        "success": !isError && isSuccess,
        "error": isError
      })}
      role="alert"
      aria-relevant="all">
        {isError && <WarningIcon />}
        {!isError && isSuccess && <SuccessIcon />}
        {message}
    </div>
  );
}

StatusMessage.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
};
