import PropTypes from "prop-types";
import { Input } from "~/components/Base/Input/Input";

export function NumberInput({
  ariaDescribedBy,
  format,
  label,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  // Prevent user from entering "e" in the number input since Clinic doesn't handle numbers like 1e4
  const handleKeyDown = (evt) => {
    if (evt.key === "e") {
      evt.preventDefault();
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const [, rhs] = value.split(/[.,]/);
    const decimals = rhs ? rhs.length : 0;
    onChange(value, decimals);
  };

  const getReadOnlyValue = () => {
    const decimals = format && Number.parseInt(format);

    return decimals ? Number.parseFloat(value).toFixed(decimals) : value;
  };

  const disableWheelScroll = () => {
    document.activeElement.blur();
  };

  const handleFocus = (evt) => {
    evt.target.addEventListener("wheel", disableWheelScroll);
  };

  const handleBlur = (evt) => {
    evt.target.removeEventListener("wheel", disableWheelScroll);
  };

  return (
    <Input
      ariaDescribedBy={ariaDescribedBy}
      ariaLabel={label}
      disabled={readOnly}
      fullWidth={false}
      id={oid}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      type="number"
      valid={valid}
      value={readOnly ? getReadOnlyValue() : value}
    />
  );
}

NumberInput.propTypes = {
  ariaDescribedBy: PropTypes.string,
  format: PropTypes.number,
  label: PropTypes.string,
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
