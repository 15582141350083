import PropTypes from "prop-types";
import { createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ModalPortal } from "./ModalPortal";
import { Button } from "~/components/Base/Button/Button";
import CloseIcon from "~/content/icons/cancel.svg?react";
import "./Modal.css";

export function Modal({ children, isOpen, onClose, title }) {
  const { t } = useTranslation();
  const closeButton = createRef();
  const modalWrapper = createRef();

  const closeOnBackgroundClick = (e) => {
    if (e.target === modalWrapper.current) {
      onClose();
    }
  };

  useEffect(() => {
    const closeOnEscapeKey = (e) => (e.key === "Escape" ? onClose() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  useEffect(() => {
    if (closeButton.current) {
      closeButton.current.focus();
    }
  }, [closeButton]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalPortal wrapperId="root">
      {/* biome-ignore lint/a11y/useKeyWithClickEvents: There is a listener on body to close the modal on esc key */}
      <div
        ref={modalWrapper}
        // biome-ignore lint/a11y/useSemanticElements: TODO - could be replaced with the dialog element
        role="dialog"
        aria-modal="true"
        className="modal"
        aria-labelledby="modal-title"
        onClick={closeOnBackgroundClick}
      >
        <div className="modal-content">
          <header className="modal-content__header">
            <div id="modal-title">{title}</div>
            <Button
              buttonRef={closeButton}
              color="light-gray"
              type="circular"
              icon={<CloseIcon />}
              label={t("general.close")}
              onClick={onClose}
              className="close-button"
            />
          </header>
          <main className="modal-content__body">
            <div className="modal-content__scroll-container">{children}</div>
          </main>
        </div>
      </div>
    </ModalPortal>
  );
}

Modal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
