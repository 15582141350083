import PropTypes from "prop-types";
import { useRef } from "react";

import { Select } from "~/components/Base/Select/Select";
import { toToggleGroupValues } from "../shared/toToggleGroupValues";

export function Dropdown({
  ariaDescribedBy,
  choices,
  readOnly,
  oid,
  onChange,
  valid,
  value,
}) {
  const options = useRef(toToggleGroupValues(choices));

  return (
    <Select
      ariaDescribedBy={ariaDescribedBy}
      disabled={readOnly}
      id={oid}
      onChange={onChange}
      options={options.current}
      valid={valid}
      value={value}
    />
  );
}

Dropdown.propTypes = {
  ariaDescribedBy: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      choiceValue: PropTypes.string.isRequired,
      choiceText: PropTypes.string.isRequired,
    })
  ),
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
