/* eslint-disable no-useless-escape, no-control-regex */

export const isNumericFormat = /^(\d+)((\.\d+)|)$/;

// String since used both as HTML element pattern and match regex
export const pinCodeFormat = "[0-9]{4,}";

export const phoneNumberFormat = /^((\+)|(00)|())[0-9]{3,14}$/;

export const emailFormat =
  /^((([a-zA-Z0-9]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])+(\.([a-zA-Z0-9]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])+)*)|((")(((( )|(\t)*(\r\n))?( )+)?(([\x21]|[\x23-\x5b]|[\x5d-\x7e]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])|(\\([\x21-\x7e]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF]))))*((( )|(\t)*(\r\n))?( )+)?(")))@((([a-z]|\d|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])|(([a-zA-Z0-9]|\d|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])([a-zA-Z0-9]|\d|-|\.|_|~|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])*([a-zA-Z0-9]|\d|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])))\.)+(([a-z]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])|(([a-zA-Z0-9]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])([a-zA-Z0-9]|\d|-|\.|_|~|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])*([a-zA-Z0-9]|[\u00A1-\u200A\u200C-\uD7FF\uF900-\uFDCF\uFDF0-\uFEFE\uFF00-\uFFEF])))\.?$/;

export const formatSize = (bytes) => {
  const sizeSymbols = ["KB", "MB", "GB", "TB", "PB", "EB"];
  const smallestValue = 0.1;

  if (bytes === 0) {
    return "0 KB";
  }

  if (bytes < 1024) {
    const kb = bytes / 1024;
    return `${Math.max(kb, smallestValue).toFixed(1)} KB`;
  }

  let i = -1;
  let value = bytes;

  do {
    value = value / 1024;
    i++;
  } while (value >= 1024);

  const numberOfDecimals = i > 0 ? 1 : 0; // Only show decimals for MB and up
  return `${Math.max(value, smallestValue).toFixed(numberOfDecimals)} ${
    sizeSymbols[i]
  }`;
};
