import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { getItemComponent } from "../FormItem/items";
import { FormItem } from "../FormItem/FormItem";
import { FormItemGroupLabel } from "../FormHeader/FormItemGroupLabel/FormItemGroupLabel";
import { StaggeredAnimation } from "~/components/Base/Box/StaggeredAnimation/StaggeredAnimation";

export function FormItemGroup({
  control,
  errors,
  formData,
  formHeaderRef,
  itemGroup,
  onAnimationEnd,
  onChange: updateFormData,
  onReset,
  readOnly,
}) {
  const updateValue = useCallback(
    (oid, value, format) => {
      updateFormData(oid, value, format);
    },
    [updateFormData]
  );

  if (!itemGroup) {
    return null;
  }

  return (
    <StaggeredAnimation
      animationKey={itemGroup?.oid}
      onAnimationEnd={onAnimationEnd}
    >
      {itemGroup?.label && <FormItemGroupLabel label={itemGroup?.label} />}
      {itemGroup?.items
        .filter(
          ({ isVisible, itemType, htmlType }) =>
            getItemComponent(itemType, htmlType) && isVisible
        )
        .map((item) => {
          const {
            choices,
            htmlType,
            isVisible,
            itemType,
            label,
            layout,
            oid,
            reactHookFormValidation,
          } = item;

          const ItemComponent = getItemComponent(itemType, htmlType);

          return (
            <FormItem
              control={control}
              error={errors !== null ? errors[oid] : null}
              isVisible={isVisible}
              itemType={itemType}
              key={oid}
              label={label}
              oid={oid}
              onChange={updateValue}
              onReset={onReset}
              readOnly={readOnly}
              validation={reactHookFormValidation}
              value={formData[oid]}
              render={({
                ariaDescribedby,
                onBusy,
                onChange,
                readOnly,
                valid,
                value,
              }) => (
                <ItemComponent
                  ariaDescribedby={ariaDescribedby}
                  choices={choices}
                  disabledDates={item.disabledDates}
                  format={formData[`${oid}__format`] ?? undefined}
                  formHeaderRef={formHeaderRef}
                  label={label}
                  layout={layout}
                  maxDate={item.maxDate}
                  oid={oid}
                  onBusy={onBusy}
                  onChange={onChange}
                  readOnly={readOnly}
                  valid={valid}
                  value={value}
                />
              )}
            />
          );
        })}
    </StaggeredAnimation>
  );
}

FormItemGroup.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  formData: PropTypes.object,
  formHeaderRef: PropTypes.object,
  itemGroup: PropTypes.object,
  onAnimationEnd: PropTypes.func,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  readOnly: PropTypes.bool,
};
