import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, useLocation } from "react-router";
import { SWRConfig } from "swr";
import {
  LoadingContext,
  LoadingContextProvider,
  actionTypes,
} from "~/context/LoadingContext.jsx";
import { BusyStateContextProvider } from "~/context/BusyStateContext";
import { VideoContextProvider } from "~/context/VideoContext";
import "~/localization/i18n.js";
import { useLoadingOverlayDelay } from "~/hooks/useLoadingOverlayDelay";
import { ErrorBoundary } from "~/components/Base/ErrorBoundary/ErrorBoundary";
import { LoadingOverlay } from "~/components/Base/LoadingOverlay/LoadingOverlay";
import { ScrollToTop } from "~/components/ScrollToTop/ScrollToTop";
import {
  ShowLoadingContext,
  ShowLoadingContextProvider,
} from "~/context/ShowLoadingContext";
import { ApplicationRoutes } from "~/components/ApplicationRoutes/ApplicationRoutes";
import { InactivityTracker } from "~/components/InactivityTracker/InactivityTracker";
import { broadcastChannelManager } from "~/broadcastChannel/broadcastChannelManager";
import { http } from "~/services/http";
import { useSignOut } from "~/hooks/useSignout";

import "./App.css";

function Main() {
  const { i18n, ready: translationsReady } = useTranslation(undefined, {
    useSuspense: false,
  });
  const { isLoading, dispatch } = useContext(LoadingContext);
  const { showLoading, setShowLoading } = useContext(ShowLoadingContext);
  const showLoadingOverlay = useLoadingOverlayDelay(isLoading);
  const { pathname } = useLocation();

  document.body.dir = i18n.dir();
  document.documentElement.lang = i18n.language;

  useEffect(() => {
    broadcastChannelManager.createChannel();

    return () => {
      broadcastChannelManager.closeChannel();
    };
  }, []);

  useEffect(() => {
    setShowLoading(showLoadingOverlay);
  }, [setShowLoading, showLoadingOverlay]);

  useEffect(() => {
    const LOADING_KEY = "translations";
    const type = translationsReady
      ? actionTypes.REMOVE_LOADING_ITEM
      : actionTypes.ADD_LOADING_ITEM;

    dispatch({
      type,
      key: LOADING_KEY,
    });
  }, [dispatch, translationsReady]);

  return (
    <ErrorBoundary>
      <ScrollToTop key={pathname} />
      <InactivityTracker />
      <LoadingOverlay show={showLoading} />
      {translationsReady && <ApplicationRoutes />}
    </ErrorBoundary>
  );
}

export function App() {
  const signout = useSignOut();

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <SWRConfig
          value={{
            onError: async (err) => {
              if (err.status === http.STATUS_CODE.UNAUTHORIZED) {
                await signout();
              }
            },
          }}
        >
          <LoadingContextProvider>
            <ShowLoadingContextProvider>
              <VideoContextProvider>
                <BusyStateContextProvider>
                  <Main />
                </BusyStateContextProvider>
              </VideoContextProvider>
            </ShowLoadingContextProvider>
          </LoadingContextProvider>
        </SWRConfig>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
