import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

import "./LoadingOverlay.css";

const appRoot = document.getElementById("root");

export function LoadingOverlay({ show = true }) {
  useEffect(() => {
    document.body.classList.toggle("overlay-visible", show);

    return () => {
      document.body.classList.remove("overlay-visible");
    };
  }, [show]);

  return createPortal(
    <div
      className={classNames({
        "loading-overlay": true,
        "loading-overlay--show": show,
      })}
      aria-hidden={!show}
    > 
      <LoadingIndicator className="loading-overlay__indicator" />
    </div>,
    appRoot
  );
}

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
};
