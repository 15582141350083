import { useEffect } from "react";
import { broadcastChannelManager } from "~/broadcastChannel/broadcastChannelManager";

/**
 * Custom hook to subscribe to a broadcast channel message.
 *
 * @param {string} message - The message to subscribe to.
 * @param {function} callback - The callback function to execute when the message is received.
 */
export const useBroadcastChannel = (message, callback) => {
  useEffect(() => {
    const unsubscribe = broadcastChannelManager.subscribe(message, callback);

    return () => {
      unsubscribe();
    };
  }, [callback, message]);
};
