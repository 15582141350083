import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import dateIsTomorrow from "date-fns/isTomorrow";
import { BoxSection } from "~/components/Base/Box/BoxSection/BoxSection";
import { DateIcon } from "~/components/Base/DateIcon/DateIcon";
import { EventStatus } from "~/components/Events/event-status";
import { DateRange } from "~/components/DateRange/DateRange";
import { toPrettyLocaleDateString } from "~/helpers/date-helpers";

import "./NextEventSection.css";

export function NextEventSection({ event, ...props }) {
  const { t } = useTranslation();

  const eventDay = event.target && new Date(event.target).getDate();

  const getTodayTomorrow = () => {
    if (event.status === EventStatus.ACTIVE) {
      return t("home.next_event.today");
    }

    if (
      event.status === EventStatus.SCHEDULED &&
      dateIsTomorrow(new Date(event.target))
    ) {
      return t("home.next_event.tomorrow");
    }
  };

  const todayTomorrow = getTodayTomorrow();

  return (
    <BoxSection backgroundColor="grey" {...props}>
      <div className="next-event-section">
        <p className="next-event-section__info">
          {t("form.thank_you.next_event")}
        </p>
        <p className="next-event-section__paragraph">{event.formName}</p>
        <div className="next-event-section__date-icon">
          <DateIcon dayOfMonth={eventDay} size="small" />
        </div>
        {todayTomorrow && (
          <p className="next-event-section__paragraph">{todayTomorrow}</p>
        )}
        <p className="next-event-section__info">
          {toPrettyLocaleDateString(event.target)}
        </p>
        <p className="next-event-section__info">
          <DateRange
            label={t("home.available")}
            startDate={event.availableStart}
            endDate={event.availableEnd}
            timeStart={event.timingWindowStart}
            timeEnd={event.timingWindowEnd}
          />
        </p>
      </div>
    </BoxSection>
  );
}

NextEventSection.propTypes = {
  event: PropTypes.shape({
    availableStart: PropTypes.string,
    availableEnd: PropTypes.string,
    formName: PropTypes.string,
    target: PropTypes.string,
    timingWindowEnd: PropTypes.string,
    timingWindowStart: PropTypes.string,
    status: PropTypes.string,
  }),
};
