import { appInsightsTelemetry } from "~/logging/applicationInsightsService";

export const BROADCAST_MESSAGE = Object.freeze({
  CHANGE_PIN: "change-pin",
  LOGOUT: "logout",
});

class BroadCastChannelManager {
  constructor() {
    this.channel = null;
    this.subscribers = new Map();
    this.handleMessage = this.handleMessage.bind(this);
  }

  createChannel() {
    if (!this.channel) {
      this.channel = new BroadcastChannel("vm-channel");
      this.channel.addEventListener("message", this.handleMessage);
    }
  }

  closeChannel() {
    if (this.channel) {
      this.channel.removeEventListener("message", this.handleMessage);
      this.channel.close();
      this.channel = null;
    }
  }

  handleMessage(event) {
    const message = event.data;
    if (this.subscribers.has(message)) {
      const callbacks = this.subscribers.get(message);
      
      for (const callback of callbacks) {
        callback();
      }
    }
  }

  sendMessage(message) {
    if (!this.channel) {
      appInsightsTelemetry.trackTrace('No message sent. BroadcastChannel has not been created');
      return;
    }
  
    this.channel.postMessage(message);
  }

  unsubscribe(message, callback) {
    if (this.subscribers.has(message)) {
      const currentSubscribers = this.subscribers.get(message);
      const newSubscribers = currentSubscribers.filter(
        (subscriber) => subscriber !== callback
      );
      this.subscribers.set(message, newSubscribers);
    }
  }

  subscribe(message, callback) {
    const currentSubscribers = this.subscribers.get(message);
    const newSubscribers = currentSubscribers
      ? [...currentSubscribers, callback]
      : [callback];
    this.subscribers.set(message, newSubscribers);

    return () => this.unsubscribe(message, callback);
  }
}

export const broadcastChannelManager = new BroadCastChannelManager();
