import PropTypes from "prop-types";
import { useTrimOnChange } from "~/hooks/useTrimOnChange";
import { Input } from "~/components/Base/Input/Input";

export function SingleLineText({
  ariaDescribedBy,
  label,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  const { trimAll, trimStart } = useTrimOnChange(onChange);

  return (
    <Input
      ariaDescribedBy={ariaDescribedBy}
      ariaLabel={label}
      disabled={readOnly}
      fullWidth
      id={oid}
      onBlur={trimAll}
      onChange={trimStart}
      valid={valid}
      value={value}
    />
  );
}

SingleLineText.propTypes = {
  ariaDescribedBy: PropTypes.string,
  label: PropTypes.string,
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
