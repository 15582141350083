import useSWRMutation from "swr/mutation";
import { http } from "~/services/http";
import { fileDownload } from "~/helpers/file-download";

async function downloadFile(url, fileGuid, fileName) {
  const file = await http.getFile(url, { fileGuid });
  fileDownload(file, fileName, file.type);
}

export const useDownloadStudyFile = (fileGuid) => {
  const fetcher = (url, { arg }) => {
    return downloadFile(url, fileGuid, arg);
  };

  const {
    trigger: download,
    isMutating: isDownloading,
    error,
  } = useSWRMutation(`/api/studyfile/${fileGuid}/download`, fetcher);

  return { download, isDownloading, error };
};
