import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React from "react";
import { DateTimeItem } from "../shared/DateTimeItem";
import { dateFormatType } from "~/helpers/date-helpers";

export function Time({
  ariaDescribedBy,
  label,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  const { t } = useTranslation();

  return (
    <DateTimeItem
      ariaDescribedBy={ariaDescribedBy}
      label={label}
      oid={oid}
      onChange={(value) => onChange(value, dateFormatType.TIME)}
      options={{ noCalendar: true }}
      placeholder={t("form.date_input.select_time")}
      readOnly={readOnly}
      valid={valid}
      value={value}
    />
  );
}

Time.propTypes = {
  ariaDescribedBy: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
  oid: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.string,
};
