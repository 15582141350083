import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router";
import ArrowIcon from "~/content/icons/arrow.svg?react";

import "./UnscheduledEventListItem.css";

export function UnscheduledEventListItem({ event }) {
  return (
    <RouterLink
      className="unscheduled-event-list-item"
      to={`/events/${event.formId}/form/1`}
    >
      <h3 className="unscheduled-event-list-item__label">{event.formName}</h3>
      <ArrowIcon className="unscheduled-event-list-item__icon" />
    </RouterLink>
  );
}

UnscheduledEventListItem.propTypes = {
  event: PropTypes.shape({
    formName: PropTypes.string,
    formId: PropTypes.string.isRequired,
  }).isRequired,
};
