import { dateFormatType } from "~/helpers/date-helpers";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DateTimeItem } from "../shared/DateTimeItem";

export function DateInput({
  ariaDescribedBy,
  label,
  maxDate,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  const { t } = useTranslation();

  return (
    <DateTimeItem
      ariaDescribedBy={ariaDescribedBy}
      label={label}
      oid={oid}
      maxDate={maxDate}
      onChange={(value) => onChange(value, dateFormatType.DATE)}
      options={{ enableTime: false }}
      placeholder={t("form.date_input.select_date")}
      readOnly={readOnly}
      valid={valid}
      value={value}
    />
  );
}

DateInput.propTypes = {
  ariaDescribedBy: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  maxDate: PropTypes.object,
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.string,
};
