import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSWRConfig } from "swr";
import { Button } from "~/components/Base/Button/Button";
import { errorDescribedBy } from "~/components/Base/ValidationError/errorDescribedBy";
import {
  DisplayThumbnail,
  FileDetails,
} from "~/components/Base/FileDetails/FileDetails";
import { FilePreview } from "~/components/Base/FilePreview/FilePreview";
import { FileSelect } from "~/components/Base/FileSelect/FileSelect";
import { FileUploadProgress } from "~/components/Base/FileUploadProgress/FileUploadProgress";
import { http } from "~/services/http";
import TrashIcon from "~/content/icons/trash.svg?react";
import { Thumbnail } from "~/components/Base/Thumbnail/Thumbnail";
import { ValidationError } from "~/components/Base/ValidationError/ValidationError";
import { useFetchStudyFile } from "~/hooks/api/studyFile/useFetchStudyFile";
import { useDownloadStudyFile } from "~/hooks/api/studyFile/useDownloadStudyFile";
import { splitFileIntoChunks } from "~/helpers/splitFileIntoChunks";
import { useMergeChunks } from "~/hooks/api/studyFile/useMergeChunks";
import { useUploadFile } from "~/hooks/api/studyFile/useUploadFile";
import { useFetchFileInfo } from "~/hooks/api/studyFile/useFetchFileInfo";

import "./FileUpload.css";

export function FileUpload({
  ariaDescribedBy,
  layout,
  onBusy,
  oid,
  onChange,
  readOnly,
  value,
}) {
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const shouldAbort = useRef(false);

  const generateThumb = layout.displayThumbnail !== DisplayThumbnail.NONE;
  const submittedFile =
    value?.FileName
      ? {
          fileGuid: value.FileGuid,
          fileName: value.FileName,
          fileSize: value.FileSize,
          thumbnail: value.Thumbnail,
        }
      : null;

  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState();
  const { studyFile } = useFetchStudyFile(value?.fileGuid);
  const { download, isDownloading } = useDownloadStudyFile(
    submittedFile?.fileGuid || value?.fileGuid
  );
  const { mergeChunks } = useMergeChunks();
  const { upload } = useUploadFile();
  const { fetchFileInfo } = useFetchFileInfo();
  const { mutate } = useSWRConfig()

  const fileInfo = submittedFile ?? studyFile;

  // Keep track of whether file upload is mounted or not,
  // this since progress could be updated after user navigated away during active upload
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateFileInfo = (newFileInfo) => {
    // Update SWR cache with new file info
    mutate(`/api/studyfile/${newFileInfo?.fileGuid}`, newFileInfo);
    onChange(
      newFileInfo
        ? {
            studyFileId: newFileInfo.studyFileId,
            studyId: newFileInfo.studyId,
            studySiteId: newFileInfo.studySiteId,
            fileGuid: newFileInfo.fileGuid,
          }
        : null
    );
  };

  const updateIsUploading = (nextIsUploading) => {
    setIsUploading(nextIsUploading);
    onBusy(nextIsUploading);
  };

  const cancelFileUpload = () => {
    shouldAbort.current = true;
  };

  const uploadFile = async (e) => {
    setError(null);
    const fileList = e.target.files;
    if (fileList.length < 1) {
      return;
    }

    updateIsUploading(true);

    const file = fileList[0];
    const totalSize = file.size;

    const fileName = file.name;
    const fileChunks = splitFileIntoChunks(file, totalSize);
    const totalParts = fileChunks.length;

    if (totalParts < 1) {
      setError({ message: t("form.file_upload.file_empty") });
      updateIsUploading(false);
      e.target.value = null;
      return;
    }

    // Upload all chunks
    try {
      let uuid = "";
      for (
        let partIndex = 0;
        !shouldAbort.current && partIndex < totalParts;
        partIndex++
      ) {
        const formData = new FormData();
        formData.append("generateThumb", generateThumb);
        formData.append("partIndex", partIndex);
        formData.append("totalParts", totalParts);
        formData.append("totalSize", totalSize);
        formData.append("uuid", uuid);
        formData.append("fileName", fileName);
        formData.append("file", fileChunks[partIndex]);

        uuid = await upload({
          formData,
          progressHandler: (event) => {
            const partProgress = Math.round((partIndex / totalParts) * 100);
            const totalProgress =
              Math.round((event.loaded / event.total / totalParts) * 100) +
              partProgress;
            isMounted.current && setProgress(totalProgress);
          },
          abortCallback: () => {
            return shouldAbort.current;
          },
        });
      }

      // Aborted XMLHttpRequest will throw error
      // However, chunk might not be aborted if using fast internet connection due to polling in postWithProgress
      if (shouldAbort.current) {
        setError({ message: t("form.file_upload.canceled") });
        updateIsUploading(false);
      } else {
        // All chunks are uploaded. If more than one chunk, ensure to merge them to one file
        if (totalParts > 1) {
          uuid = await mergeChunks({
            uuid,
            formData: {
              generateThumb,
              totalParts,
              totalSize,
              fileName,
            },
          });
        }
        
        const uploadedFileInfo = await fetchFileInfo(uuid);

        // NOTE: reset uploading flag before applying new file info to avoid false busy validation
        updateIsUploading(false);
        updateFileInfo(uploadedFileInfo);
      }
    } catch (err) {
      if (isMounted.current) {
        const message =
          err.status && err.status === http.STATUS_CODE.ABORTED
            ? t("form.file_upload.canceled")
            : err.message ?? t("form.file_upload.file_error");
        setError({ message: message });
        updateIsUploading(false);
      }
    } finally {
      setProgress(0);
      shouldAbort.current = false;
    }

    e.target.value = null;
  };

  const removeFile = () => {
    updateFileInfo(null);
  };

  const downloadFile = async () => {
    try {
      await download(fileInfo.fileName);
    } catch (err) {
      setError({
        message: err.message ?? t("form.file_upload.download_error"),
      });
    }
  };

  return (
    <div className="file-upload">
      <FileSelect
        ariaDescribedBy={ariaDescribedBy}
        label={t("form.file_upload.label")}
        id={oid}
        onChange={uploadFile}
        disabled={readOnly || !!studyFile || isUploading}
      />
      {error && (
        <ValidationError id={errorDescribedBy(oid)} message={error?.message} />
      )}
      <FilePreview pending={isUploading || !fileInfo}>
        {isUploading ? (
          <FileUploadProgress percent={progress} onCancel={cancelFileUpload} />
        ) : fileInfo ? (
          <>
            <FileDetails
              fileName={fileInfo.fileName}
              fileSize={fileInfo.fileSize}
              displayThumbnail={layout.displayThumbnail}
              isProcessing={isDownloading}
              onClick={downloadFile}
              thumbnail={fileInfo.thumbnail}
              defaultThumbnail={
                <Thumbnail
                  className="file-details__thumbnail"
                  fileName={fileInfo.fileName}
                  onClick={downloadFile}
                />
              }
            />
            {!readOnly && (
              <Button
                className="file-upload__button--trash"
                size="large"
                color="light-gray"
                type="circular"
                label={t("form.file_upload.remove")}
                icon={<TrashIcon />}
                onClick={removeFile}
              />
            )}
          </>
        ) : (
          t("form.file_upload.placeholder")
        )}
      </FilePreview>
    </div>
  );
}

const preSubmitValue = PropTypes.shape({
  studyFileId: PropTypes.number,
  studyId: PropTypes.number,
  studySiteId: PropTypes.number,
  fileGuid: PropTypes.string,
});

const postSubmitValue = PropTypes.shape({
  FileGuid: PropTypes.string,
  FileName: PropTypes.string,
  FileSize: PropTypes.number,
  Thumbnail: PropTypes.string,
});

FileUpload.propTypes = {
  ariaDescribedBy: PropTypes.string,
  layout: PropTypes.shape({
    displayThumbnail: PropTypes.oneOf(Object.values(DisplayThumbnail)),
  }),
  oid: PropTypes.string,
  onBusy: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([preSubmitValue, postSubmitValue]),
};
