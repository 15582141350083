import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { toPrettyLocaleDateString } from "~/helpers/date-helpers";
import { DateRange } from "~/components/DateRange/DateRange";

import "./NextEventDetails.css";
export function NextEventDetails({
  availableEnd,
  availableStart,
  formName,
  isFuture,
  target,
  timingWindowStart,
  timingWindowEnd,
}) {
  const { t } = useTranslation();

  return (
    <div className="next-event-details">
      <h3 className="next-event-details__form-name">{formName}</h3>
      {!isFuture && (
        <p className="next-event-details__info">
          {toPrettyLocaleDateString(target)}
        </p>
      )}
      <p className="next-event-details__info">
        <DateRange
          label={t("home.available")}
          startDate={availableStart}
          endDate={availableEnd}
          timeStart={timingWindowStart}
          timeEnd={timingWindowEnd}
        />
      </p>
    </div>
  );
}

NextEventDetails.propTypes = {
  availableEnd: PropTypes.string,
  availableStart: PropTypes.string,
  formName: PropTypes.string,
  isFuture: PropTypes.bool,
  target: PropTypes.string,
  timingWindowStart: PropTypes.string,
  timingWindowEnd: PropTypes.string,
};
