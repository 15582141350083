import classNames from "classnames";
import PropTypes from "prop-types";
import { useRef } from "react";

import { NumericScale } from "./NumericScale/NumericScale";
import { VasScaleThumb } from "./VasScaleThumb/VasScaleThumb";
import { VasScaleTicks } from "./VasScaleTicks/VasScaleTicks";
import ResetIcon from "~/content/icons/reset.svg?react";
import LockIcon from "~/content/icons/lock.svg?react";

import "./VasScale.css";

const gradientColors = [
  "#5eb158",
  "#81bd54",
  "#9cc753",
  "#b0ce51",
  "#cad850",
  "#dbd54e",
  "#e0c149",
  "#de9a43",
  "#dd713b",
  "#da3f33",
];

export function VasScale({
  ariaDescribedBy,
  fullColor = true, 
  id,
  lineScale = true,
  numericFeedbackLabel,
  numericScale = true,
  onChange,
  readOnly = false,
  rtl = false,
  showMinMaxLabels = false,
  showNumericFeedback = true,
  valid = true,
  value,
  vasLegendLabels = [],
  vertical = false,
}) {
  const trackRef = useRef(null);
  vasLegendLabels.sort((a, b) => a.choiceValue - b.choiceValue);
  const minLabel = vasLegendLabels[0]?.choiceText;
  const maxLabel = vasLegendLabels[1]?.choiceText;

  const getThumbColor = (value) => {
    if (value === 100) {
      return gradientColors[gradientColors.length - 1];
    }

    return gradientColors[Math.floor(value / 10)];
  };

  const formatValue = (value) => {
    if (value < 0) {
      return 0;
    }

    if (value > 100) {
      return 100;
    }

    return Math.round(value);
  };

  const calculateValue = ({ clientY, clientX, touches }) => {
    const trackElement = trackRef.current;
    const { left, top, width, height } = trackElement.getBoundingClientRect();

    if (touches) {
      clientX = touches[0].clientX;
      clientY = touches[0].clientY;
    }

    const value = vertical
      ? (1 - (clientY - top) / height) * 100
      : ((clientX - left) / width) * 100;

    return rtl && !vertical ? formatValue(100 - value) : formatValue(value);
  };

  const handleMouseDown = (evt) => {
    if (readOnly) {
      return;
    }

    // Check that the main (usually left) mouse button is pressed
    if (evt.nativeEvent.button === 0) {
      onChange(calculateValue(evt));

      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMove);
    }
  };

  const handleTouchStart = (evt) => {
    if (readOnly) {
      return;
    }

    onChange(calculateValue(evt));

    document.addEventListener("touchend", handleTouchEnd);
    document.addEventListener("touchmove", handleMove);
  };

  const handleMove = (evt) => {
    onChange(calculateValue(evt));
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mousemove", handleMove);
  };

  const handleTouchEnd = () => {
    document.removeEventListener("touchend", handleTouchEnd);
    document.removeEventListener("touchmove", handleMove);
  };

  const handleKeyDown = (evt) => {
    const isIncrement =
      evt.key === "ArrowUp" ||
      (!rtl && evt.key === "ArrowRight") ||
      (rtl && evt.key === "ArrowLeft");

    const isDecrement =
      evt.key === "ArrowDown" ||
      (!rtl && evt.key === "ArrowLeft") ||
      (rtl && evt.key === "ArrowRight");

    if (isIncrement) {
      evt.preventDefault();
      onChange(formatValue(value + 1));
    } else if (isDecrement) {
      evt.preventDefault();
      onChange(formatValue(value - 1));
    }
  };

  const handleReset = () => {
    const value = null;
    const format = undefined;
    const isReset = true;

    onChange(value, format, isReset);
  };

  const getControls = () => {
    return (
      <div className="vas-scale__controls">
        {numericFeedbackLabel && vertical && (
          <div className="vas-scale__numeric-feedback-label">
            {numericFeedbackLabel}
          </div>
        )}
        {showNumericFeedback && (
          <output htmlFor={id} className="vas-scale__numeric-feedback">
            {value}
          </output>
        )}
        {readOnly ? (
          <LockIcon />
        ) : (
          <button
            onClick={handleReset}
            className="vas-scale__reset-button"
            aria-label="Reset"
            type="button"
          >
            <ResetIcon className="vas-scale__reset-icon" />
          </button>
        )}
      </div>
    );
  };

  const showTopAndBottomLabels = vertical && showMinMaxLabels;

  return (
    <>
      {showTopAndBottomLabels && (
        <div className="vas-scale__top-label">{maxLabel}</div>
      )}
      <div
        className={classNames({
          "vas-scale": true,
          "vas-scale--vertical": vertical,
          "vas-scale--full-color": fullColor,
          "vas-scale--error": !valid,
          "vas-scale--read-only": readOnly,
        })}
        aria-describedby={ariaDescribedBy}
      >
        <div className="vas-scale__section">
          {vertical && getControls()}
          {!showTopAndBottomLabels && (
            <>
              <div className="vas-scale__label-wrapper">
                <div className="vas-scale__label">{minLabel}</div>
                <div className="vas-scale__label">{maxLabel}</div>
              </div>
              <div className="vas-scale__label-ticks" />
            </>
          )}
        </div>
        <div>
          <div
            id={id}
            ref={trackRef}
            className="vas-scale__track"
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            onKeyDown={handleKeyDown}
            role="slider"
            tabIndex="0"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-valuenow={value}
            aria-orientation={vertical ? "vertical" : "horizontal"}
          >
            {lineScale && <VasScaleTicks vertical={vertical} />}
            {Number.isInteger(Number.parseInt(value)) && (
              <VasScaleThumb
                color={
                  fullColor
                    ? getThumbColor(value)
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--color-blue")
                }
                position={rtl && !vertical ? 100 - value : value}
                vertical={vertical}
              />
            )}
          </div>
        </div>
        <div className="vas-scale__section">
          {numericScale && <NumericScale vertical={vertical} rtl={rtl} />}
          {!vertical && getControls()}
        </div>
      </div>
      {showTopAndBottomLabels && (
        <div className="vas-scale__bottom-label">{minLabel}</div>
      )}
    </>
  );
}

VasScale.propTypes = {
  ariaDescribedBy: PropTypes.string,
  fullColor: PropTypes.bool,
  id: PropTypes.string.isRequired,
  lineScale: PropTypes.bool,
  numericFeedbackLabel: PropTypes.string,
  numericScale: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  rtl: PropTypes.bool,
  showMinMaxLabels: PropTypes.bool,
  showNumericFeedback: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.number,
  vertical: PropTypes.bool,
  vasLegendLabels: PropTypes.arrayOf(
    PropTypes.shape({
      choiceText: PropTypes.string.isRequired,
      choiceValue: PropTypes.string.isRequired,
    })
  ),
};
