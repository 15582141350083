import "./FileUploadProgress.css";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "~/components/Base/Button/Button";
import { CircularProgress } from "../CircularProgress/CircularProgress";
import CancelIcon from "~/content/icons/cancel.svg?react";

export function FileUploadProgress({ onCancel, percent }) {
  const { t } = useTranslation();

  return (
    <section className="file-upload-progress">
      <span>{t("form.file_upload.uploading", { percent })}</span>
      <div className="file-upload-progress__indicator">
        <CircularProgress value={percent} color="green" size="large" />
        <Button
          type="circular"
          icon={<CancelIcon />}
          color="transparent"
          className="file-upload-cancel"
          label={t("form.file_upload.cancel_upload")}
          onClick={onCancel}
        />
      </div>
    </section>
  );
}

FileUploadProgress.propTypes = {
  onCancel: PropTypes.func.isRequired,
  percent: PropTypes.number.isRequired,
};
