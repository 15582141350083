import { useMemo } from "react";
import PropTypes from "prop-types";
import { NumericRatingScale } from "~/components/Base/NumericRatingScale/NumericRatingScale";
import { toToggleGroupValues } from "../shared/toToggleGroupValues";

export function Nrs({
  ariaDescribedBy,
  choices,
  layout,
  oid,
  onChange,
  readOnly,
  valid,
  value,
}) {
  const options = useMemo(() => toToggleGroupValues(choices), [choices]);
  const { anchorLabelStart, anchorLabelEnd } = layout;

  return (
    <NumericRatingScale
      anchorLabelStart={anchorLabelStart}
      anchorLabelEnd={anchorLabelEnd}
      ariaDescribedBy={ariaDescribedBy}
      choices={options}
      id={oid}
      layout={layout}
      onChange={onChange}
      readOnly={readOnly}
      valid={valid}
      value={value}
    />
  );
}

Nrs.propTypes = {
  ariaDescribedBy: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      choiceText: PropTypes.string.isRequired,
      choiceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      choiceValueTyped: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  layout: PropTypes.shape({
    anchorLabelStart: PropTypes.string,
    anchorLabelEnd: PropTypes.string,
  }).isRequired,
  oid: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  valid: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
