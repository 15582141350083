import useSWR from "swr";
import { http } from "~/services/http";

export const useFetchSubmittedForm = (formId, onSuccess) => {
  const {
    data: submittedForm,
    error,
    isLoading,
  } = useSWR(`/api/questfilled/${formId}`, http.get, {
    revalidateOnFocus: false,
    onSuccess,
  });

  return { submittedForm, isLoading, error };
};
