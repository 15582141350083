import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { BoxHeader } from "~/components/Base/Box/BoxHeader/BoxHeader";
import { CircularProgress } from "~/components/Base/CircularProgress/CircularProgress";
import { FetchError } from "~/components/Base/FetchError/FetchError";
import WarningIcon from "~/content/icons/warning.svg?react";

import "./FormHeader.css";

export const FormHeader = forwardRef(
  (
    {
      currentPage,
      formName,
      showFetchError,
      isPending,
      isReadyToSubmit,
      isThankYouPage,
      pageCount,
      sticky,
      valid,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const getText = () => {
      if (!valid) {
        return (
          <div className="form-header__validation-error">
            <WarningIcon className="form-header__warning-icon" />
            <span className="form-header__validation-error-text">
              {t("validation.please_correct")}
            </span>
          </div>
        );
      }

      if (valid) {
        return <h2 className="form-header__title">{formName}</h2>;
      }
    };

    const progressValue = isPending ? 0 : (currentPage / pageCount) * 100;

    return (
      <BoxHeader forwardedRef={ref} sticky={sticky}>
        {showFetchError ? (
          <FetchError />
        ) : (
          <div className="form-header">
            {getText()}
            <div className="form-header__progress-indicator">
              <span className="form-header__current-page-indicator">
                {currentPage}
              </span>
              /
              <span className="form-header__total-pages-indicator">
                {pageCount}
              </span>
            </div>
            <CircularProgress
              className="form-header__circular-progress"
              value={progressValue}
              success={isReadyToSubmit}
            />
          </div>
        )}
      </BoxHeader>
    );
  }
);

FormHeader.propTypes = {
  currentPage: PropTypes.number,
  formName: PropTypes.string,
  isPending: PropTypes.bool,
  isReadyToSubmit: PropTypes.bool,
  isThankYouPage: PropTypes.bool,
  pageCount: PropTypes.number,
  showFetchError: PropTypes.bool,
  sticky: PropTypes.bool,
  valid: PropTypes.bool,
};
