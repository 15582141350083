import { HtmlText } from "~/components/Base/HtmlText/HtmlText";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./FormItemGroupLabel.css";

export function FormItemGroupLabel({ className, label, ...props }) {
  return (
    <div
      className={classNames({
        "form-item-group__label": true,
        [className]: !!className,
      })}
      {...props}
    >
      <HtmlText element="h3" htmlString={label} />
    </div>
  );
}

FormItemGroupLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};
