import React from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "react-oidc-context";
import '@viedoc/viedoc-ui/css/viedoc.css';
import { App } from "./App";
import { userManager } from "./userManager";
import "~/logging/oidcTelemetryLogger"
import { handleSignoutCallback } from "~/components/SignoutCallback/handleSignoutCallback";
import { matchSignoutCallback } from "~/components/SignoutCallback/matchSignoutCallback";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider userManager={userManager} matchSignoutCallback={matchSignoutCallback} onSignoutCallback={handleSignoutCallback} >
      <App />
    </AuthProvider>
  </React.StrictMode>
);
