import { http } from "~/services/http";
import useSWR from "swr";

export const useFetchLanguages = () => {
  const {
    data: accountLanguages,
    error,
    isLoading,
  } = useSWR("/api/study/languages", http.get, {
    revalidateOnFocus: false,
  });

  return { accountLanguages, isLoading, error };
};
